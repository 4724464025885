<template>
  <div class="flex-column">
    <div class="flex">
      You can view your sidebar in a close condensed version or an open version that displays the titles of the web pages.
    </div>
    <div class="flex align-items-end justify-content-between" style="padding-top: 30px">
      <div class="flex-column" style="width: 44%">
        <div class="dropdown-label">Default Status</div>
        <Dropdown class="full-width" :model-value="selectedOption" :options="sidebarOptions" optionLabel="label" optionValue="value" @change="updateSideBarOption"></Dropdown>
      </div>
      <div style="width: 206px;">
        <submit-button @click="updateSidebarSetting" :loading="loadingState" button-text="Save Changes" data-cy="settings-website-save-sidebar" ></submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "Sidebar",
  components: {SubmitButton},
  setup() {
    const store = useStore()
    const initialState = computed(() => store.getters.getCollapsedNav)
    const selectedOption = ref(initialState.value ?? false)
    const sidebarOptions = ref([
      {
        "label": 'Closed Sidebar',
        "value": true
      },
      {
        "label": 'Open Sidebar',
        "value": false
      }
    ])

    const updateSideBarOption = (choice) => {
      selectedOption.value = choice.value
      store.commit('setPreferences', {collapsedLeftNav:choice.value})
    }

    const updateSidebarSetting = () => {
      store.dispatch('setLoading', {id: 'settings-sidebar', state: true})
      store.dispatch('updateSettings', {
        type: 'settings',
        loading: 'settings-sidebar',
        title: 'Sidebar',
        payload: {
          collapsedLeftNav: selectedOption.value
        }
      })
    }

    const loadingState = computed(() => store.getters.isLoading('settings-sidebar'))

    return {
      selectedOption,
      sidebarOptions,
      updateSideBarOption,
      updateSidebarSetting,
      loadingState
    }
  }
}
</script>

<style scoped>
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}
.half {
  width: 45%;
}
</style>
