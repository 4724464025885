<template>
    <div v-if="enabled">
        <inner-card class="card">
            <template #title>
                <div class="flex justify-content-between">
                    <div class="flex">
                        <h3 class="title"><span class="material-icons">check_circle</span>MULTI-FACTOR AUTHENTICATION ENABLED!</h3>
                    </div>
                    <div class="flex grey cursor-pointer" @click="$emit('closeMfaMsg')">
                        <span class="material-icons-outlined">close</span>
                    </div>
                </div>
            </template>
            <template #content>
                <div class="flex">
                    <div class="flex-grow-1">
                        <p>The "Multi-Factor Authentication" is now <b>ENABLED on your Account</b>.<br>Your personal account is now protected by an extra level of security. Every time you log in, you'll be asked for a temporary <b>6 digit security code</b> generated on your mobile.</p> 
                    </div>
                    <div class="flex-none">
                        <img class="cardImg" src="@/assets/img/cards/mfa-couple-image.png" alt="Secure Account">
                    </div>
                </div>
            </template>
        </inner-card>
    </div>
    <div v-else>
        <inner-card class="card">
            <template #title>
                <div class="flex justify-content-between">
                    <div class="flex">
                        <h3 class="title"><span class="material-icons">check_circle</span>MULTI-FACTOR AUTHENTICATION DISABLED!</h3>
                    </div>
                    <div class="flex grey cursor-pointer" @click="$emit('closeMfaMsg')">
                        <span class="material-icons-outlined">close</span>
                    </div>
                </div>
            </template>
            <template #content>
                <p class="body-text">The "Multi-Factor Authentication" is now <b>DISABLED</b>. You'll no longer be required to enter a 6 digit security code on sign in.</p>
            </template>
        </inner-card>   
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import InnerCard from '../common/InnerCard.vue'

    export default {
        name: "MfaMessage",
        props: ['mfa'],
        components: {
            InnerCard
        },
        setup(props) {
            const enabled = ref();
            
            onMounted( () => {
                enabled.value = props.mfa;
            });

            return {
                InnerCard,
                enabled
            }
        }  
    }
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 30px;
  background: transparent linear-gradient(180deg, #1CD37F33 0%, #0195674D 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #0000000D;
  .title {
    color: #019567;
    margin-bottom: 20px;
  }
  p {
    margin-left: 35px;
  }
}
.cardImg {
    width: 325px;
    margin: 0px 123px;
}
.material-icons {
    vertical-align: top;
    margin-right: 10px;
}
.card:deep(.card-title) {
  padding: 30px;
}

.card:deep(.content) {
  padding: 0px;
  font-size: 18px;
}
</style>