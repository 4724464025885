<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Website Settings</h2></template>
        <template #content>
          <div @click.stop="toggleAll" style="cursor: pointer;">
            <div class="flex align-items-center grey" v-if="all" data-cy="settings-collapse-all"><span
                class="material-icons-round grey">remove</span>Collapse All
            </div>
            <div class="flex align-items-center grey" v-if="!all"><span
                class="material-icons-round grey">add</span>Expand All
            </div>
          </div>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-column">
        <div><h3 class="title mb-3">Introduction</h3></div>
        <div class="flex justify-content-between">
          <div class="flex" style="font-size: 18px;">
            The 'Website Settings' area allows you to change your password, upgrade security preferences by enabling the Multi-Factor Authentication, currency preferences and sidebar status.
          </div>
        </div>
      </div>
      <spacer-h />
      <Notification v-if="pwdChangeSuccess" :type="'success'" @close="pwdSuccess = false"
                    :icon="'check_circle'"
                    :title="'password updated successfully'"/>

      <MfaMessages v-if="showMFAUpdate" :mfa=mfaStatus @closeMfaMsg="closeMfaMsg"></MfaMessages>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">password details</h3></div>
            <div v-if="expanded.password">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('password')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.password">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('password')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="collapsed">
            You will need to confirm your existing password when setting
            a new password for the clarity website.
          </div>
          <div v-if="expanded.password">
            <PasswordReset @pwdChanged="pwdChanged"/>
          </div>
        </template>
      </inner-card>

      <inner-card class="card" id="target-mfa">
        <template #title>
          <div class="flex justify-content-between" >
            <div class="flex"><h3 class="title">security preference</h3></div>
            <div v-if="expanded.security">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('security')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.security">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('security')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex justify-content-between">
            <div class="flex flex-grow-1">
              To help keep you and your financial details secure, clarity recommend turning Multi-Factor Authentication on.
              clarityONLINE now supports the use of an Authenticator app. Watch the short video by clicking the button on
              side,
              to see how to enable a Multi-Factor Authenticator app for your account.
            </div>
            <div class="flex">
              <Button @click="mfaVideoTour" class="clarity-btn clarity-gradient-grey justify-content-center" style="padding: 15px 45px; width: 206px;">Video Tour</Button>
              <Dialog v-model:visible="showMfaVideoTour" modal>
                <div class="mfaVideoDialog">
                  <div class="flex justify-content-between modal-header">
                    <h3 class="clarity-h3 blue">HOW TO SET MULTI-FACTOR AUTHENTICATION</h3>
                    <Button class="clarity-close-modal-btn" @click="closeMfaVideoTour"><span class="material-icons-round grey">close</span></Button>
                  </div>
                  <div>
                    <div class="mfaVideo clarity-margin-bottom-s">
                      <div style='padding:56.25% 0 0 0;position:relative;'>
                        <iframe src='https://vimeo.com/showcase/10605680/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
                      </div>
                    </div>
                    <p class="body-text">Want to know more about the
                      <span class="blue"><b>Multi-Factor Authentication?</b></span>
                      <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityEnablingMFABrochure.pdf" target="_blank">
                        <span class="material-icons">get_app</span>
                        <span class="small-text">Download "How to use MFA" Brochure</span>
                      </a>
                    </p>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
          <div v-if="expanded.security">
            <Security/>
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">currency preferences</h3></div>
            <div v-if="expanded.currency">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('currency')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.currency">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('currency')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div v-if="expanded.currency">
            <Currency/>
          </div>
          <div v-if="!expanded.currency" class="collapsed">Valuation pages will display in this currency when you first
            load the page. Valuation downloads will also be in the selected currency.
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">IMS PREFERENCES</h3></div>
            <div v-if="expanded.ims">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('ims')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.ims">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('ims')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
            <div v-if="expanded.ims">
              <Ims/>
            </div>
            <div v-if="!expanded.ims" class="collapsed"></div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">sidebar status</h3></div>
            <div v-if="expanded.sidebar">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('sidebar')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.sidebar">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('sidebar')"><span
                  class="material-icons-round grey" data-cy="settings-expand-sidebar">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div v-if="expanded.sidebar">
            <SidebarSetting/>
          </div>
          <div v-if="!expanded.sidebar" class="collapsed">You can view your sidebar in a close condensed version or an open version that displays the titles of the web pages.
          </div>
        </template>
      </inner-card>
      <!-- @TODO - removed for MVP release 1
      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">edit dashboard</h3></div>
            <div v-if="expanded.dashboard">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('dashboard')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.dashboard">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('dashboard')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div v-if="expanded.dashboard">
            <dashboard/>
          </div>
          <div v-if="!expanded.dashboard" class="collapsed">Lorem ipsum erat volutpat et integer ullamcorper finibus
            nisi. Curabitur non felis at quam iaculis euismod. Duis maximus nisi ut nulla pellentesque, quis auctor sem
            congue.
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">thematic actions</h3></div>
            <div v-if="expanded.thematic">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('thematic')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.thematic">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('thematic')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div v-if="expanded.thematic">
            <ThematicActions/>
          </div>
          <div v-if="!expanded.thematic" class="collapsed">Fusce quis gravida tortor. Suspendisse porta, orci sagittis
            placerat rutrum, nunc neque dapibus justo, eu rhoncus leo sem vel augue. Phasellus in facilisis tempus et
            faucibus. Aenean sagittis sagittis urna ut porta. Proin feugiat quam dolor, quis bibendum risus ultricies
            eget.
          </div>
        </template>
      </inner-card>
      -->

    </template>
  </content-card-full>
</template>

<script>
import ContentCardFull from '@/components/common/ContentCardFull'
import CardTitle from '@/components/common/CardTitle'
import InnerCard from '../common/InnerCard.vue'

import {computed, reactive, ref, inject, onBeforeUnmount} from 'vue';
import Currency from "@/components/settings/Currency";
import Ims from "@/components/settings/Ims";
//import Dashboard from "@/components/settings/dashboard";
import PasswordReset from "@/components/settings/PasswordReset";
import Security from "@/components/settings/Security";
import SidebarSetting from "@/components/settings/Sidebar";
//import ThematicActions from "@/components/settings/ThematicActions";
import Notification from "@/components/common/Notification";
import SpacerH from "../common/layout/SpacerH";
import MfaMessages from '@/components/settings/MfaMessages.vue';

export default {
  name: "TabWebSettings",
  components: {
    SpacerH,
    Notification,
    //ThematicActions,
    //Dashboard,
    Currency,
    Ims,
    Security,
    PasswordReset,
    ContentCardFull,
    CardTitle,
    InnerCard,
    SidebarSetting,
    MfaMessages
  },
  setup() {
      const all = ref(true);
      const bus = inject('bus');
      const expanded = reactive({
          password: true,
          security: true,
          currency: true,
          sidebar: true,
          ims: true,
          //dashboard: false,
          //thematic: false
      })

      const pwdSuccess = ref(false)
      const showMfaVideoTour = ref(false)
      const showMFAUpdate = ref(false)
      const mfaStatus = ref(false)

      bus.on('mfaOn', () => {
          mfaStatus.value = true;
          showMFAUpdate.value = true;

      });

      bus.on('mfaOff', () => {
          mfaStatus.value = false;
          showMFAUpdate.value = true;

      });

      bus.on('showMFAVideo', () => {
          showMfaVideoTour.value = true;
      });

      const toggleAll = () => {
          all.value = !all.value
          for (let k in expanded) {
              expanded[k] = all.value
          }
      }

      const toggleSingle = (card) => {
          expanded[card] = !expanded[card]
      }

      const closeMfaMsg = () => {
          showMFAUpdate.value = false;
      }

      const pwdChanged = () => {
          pwdSuccess.value = true
          setTimeout(() => {
              if (pwdSuccess.value === true) {
                  pwdSuccess.value = false
              }
          }, 4000)
      }

      const mfaVideoTour = () => {
          if (!showMfaVideoTour.value) {
              showMfaVideoTour.value = true;
          }
      }

      const closeMfaVideoTour = () => {
          if (showMfaVideoTour.value) {
              showMfaVideoTour.value = false;
          }
      }

      onBeforeUnmount(() => {
          bus.off('mfaOn')
          bus.off('mfaOff')
          bus.off('showMFAVideo')
      });

      return {
          all,
          expanded,
          pwdChangeSuccess: computed(() => pwdSuccess.value),
          pwdSuccess,
          showMFAUpdate,
          mfaStatus,
          showMfaVideoTour,
          pwdChanged,
          toggleAll,
          toggleSingle,
          closeMfaMsg,
          mfaVideoTour,
          closeMfaVideoTour
      }
  }
}
</script>

<style scoped lang="scss">
.card {
  box-shadow: unset;
  margin-bottom: 30px;

  .title {
    margin-bottom: 20px;
  }
}

.card:deep(.card-title) {
  padding: 30px;
}

.card:deep(.content) {
  padding: 0px;
  font-size: 18px;
}

.collapsed {
  width: 90%
}

.mfaVideoDialog {
  width: 100%;
  height: auto;
}
.mfaVideoDialog a {
  color: var(--clarity-dark-grey);
}
.mfaVideoDialog video {
  max-width: 100%;
  width: 890px;
  height: auto;
}
.material-icons {
  vertical-align: middle;
  margin-left: 20px;
}
.clarity-close-modal-btn {
  margin: 0px !important;
}
</style>
