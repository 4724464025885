<template>
  <div class="flex">
    <div class="flex flex-wrap align-items-end justify-content-between">
      <div class="flex flex-wrap mt-4 flex-grow-1">
        <div class="flex flex-grow-1">
          <h5 class="blue clarity-no-margin">Multi-Factor<br>Authentication</h5>
        </div>
        <div class="flex">
          <div class="mfaStatusBar" :class="{statusMsgDisabled: !mfa}">
            <span class="material-icons-outlined">{{ mfaStatusIcon }}</span>Status: <span :class="{statusMsg: mfa}">{{ mfaStatusMessage }}</span>
          </div>
        </div>
      </div>
      <div class="flex mt-4">
        <submit-button @click="showMFAPopover" :loading="loadingState" :button-text="mfaBtnText" ></submit-button>
      </div>
    </div>

    <!-- DISABLE MFA CONFIRMATION DIALOG BOX -->
    <Dialog class="showDisableMFABox" :modal="true" v-model:visible="disableMFAConfirmation">
      <div class="flex justify-content-between modal-header">
        <h3 class="blue">Disable Multi-Factor Authentication</h3>
        <Button class="clarity-close-modal-btn" @click="closeMFAConfirm"><span class="material-icons-round grey">close</span></Button>
      </div>
      <div>
        <p class="body-text">Are you sure you want to proceed and <span class="statusMsg">DISABLE</span> the “Multi-Factor Authentication”? For security reasons, we recommend to keep the “Multi-Factor Authentication” <span class="statusMsg">ENABLED</span>.</p>
      </div>
      <div class="flex justify-content-between actionBtns">
        <Button class="clarity-btn clarity-gradient-grey" @click="closeMFAConfirm">Dismiss</Button>
        <Button id="mfaSubmit" class="clarity-btn clarity-gradient-orange" @click="disableUserMFA">Proceed</Button>
      </div>
    </Dialog>

    <!-- ENABLE MFA QR CODE AND RESPONSE CODE BOX -->
    <Dialog class="showEnableMFABox" :modal="true" v-model:visible="enableMFA">
      <div class="flex justify-content-between modal-header">
        <h3 class="blue">Enable Multi-Factor Authentication</h3>
        <Button class="clarity-close-modal-btn" @click="closeMFASetup"><span class="material-icons-round grey">close</span></Button>
      </div>

      <div class="flex clarity-margin-top-xs">
        <div class="flex-grow-1 flex">
          <p class="body-text clarity-margin-left-s">Add extra security to your account. Every time you log in, you’ll be asked for a temporary<br><span class="blue code-text-bold">6 digit security code</span> generated on your mobile. You’ll need a computer to enable this.</p>
        </div>
        <div class="flex-none flex">
          <Button @click="showVideoGuide" class="mfaVideoBtn clarity-btn clarity-gradient-grey">Video Guide</Button>
        </div>
      </div>

      <div class="flex">
        <div style="width:40%;" class="flex-column">
          <div class="renderQrCode">
            <h4 class="light-blue clarity-margin-bottom-xs">SCAN THIS CODE<br>WITH YOUR PHONE</h4>
            <QRCodeVue3
              :width="180"
              :height="180"
              :value="qrcode"
              :imageOptions="{ hideBackgroundDots: true, imageSize: 0.5, margin:5.0}"
              :image="require('@/assets/logos/clarity-logo-C-blue.svg')"
              :dotsOptions="{type:'square',color:'#0058A9'}"
              :cornersDotOptions="{type:'sqaure',color:'#0058A9'}"
              :cornersSquareOptions="{type:'square',color:'#0058A9'}"
            />
            <p class="small-text grey clarity-margin-top-el">Need help? Check our<br>"<a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityEnablingMFABrochure.pdf" target="_blank">Enabling MFA</a>" Brochure.</p>
          </div>
        </div>
        <div style="width:60%" class="flex-column">
          <div class="clarity-margin-top-el">
            <h5 class="blue">Step 01:</h5>
            <p class="body-text">Download an Authenticator app <span class="greyItalic">(If you don’t have one already)</span>.</p>
            <div class="osIcons">
              <p class="body-text"><a href="https://apps.apple.com/gb/app/microsoft-authenticator/id983156458" target="_blank"><img src="@/assets/icons/iphone-icon.png"><span class="clarity-margin-right-s">iPhone</span></a><a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank"><img src="@/assets/icons/android-icon.png"><span class="clarity-margin-right-s">Android</span></a><a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank"><img src="@/assets/icons/windows-icon.png"><span class="clarity-margin-right-s">Windows</span></a></p>
            </div>
          </div>
          <div class="clarity-margin-top-m">
            <h5 class="blue">Step 02:</h5>
            <p class="body-text">Once downloaded and installed the Authenticator app, you need to scan<br>the QR Code you can find on side of this page.</p>
          </div>
          <div class="mfaCodeEntry">
            <h5 class="blue">Step 03:</h5>
            <p class="body-text">Please enter the <span class="blue code-text-bold">6 digit security code</span> from your Authenticator App<br>and then click on "Validate".</p>
            <div v-if="mfaCodeError" class="clarity-margin-right-xs">
              <div class="codeErrorCard">
                <div class="flex justify-content-between mfaErrorTitle">
                  <div class="flex">
                    <h3><span class="material-icons">error</span>VALIDATION FAILED</h3>
                  </div>
                  <div class="flex grey cursor-pointer" @click="closeCodeError">
                    <span class="material-icons-outlined">close</span>
                  </div>
                </div>
                <div>
                  <p class="mfaErrorMsg">The code you entered is not valid. Please try again.</p>
                </div>
              </div>
            </div>
            <InputText autofocus="true" id="boxOne" class="mfaInputValue" :class="{ mfaCodeErrorInput: mfaCodeError}" type="text" v-model="mfaCodeOne" @keyup=nextCodeValue(2) :maxlength="1" />
            <InputText id="boxTwo" class="mfaInputValue" :class="{ mfaCodeErrorInput: mfaCodeError}" type="text" v-model="mfaCodeTwo" @keyup=nextCodeValue(3) :maxlength="1" />
            <InputText id="boxThree" class="mfaInputValue" :class="{ mfaCodeErrorInput: mfaCodeError}" type="text" v-model="mfaCodeThree" @keyup=nextCodeValue(4) :maxlength="1" />
            <InputText id="boxFour" class="mfaInputValue" :class="{ mfaCodeErrorInput: mfaCodeError}" type="text" v-model="mfaCodeFour" @keyup=nextCodeValue(5) :maxlength="1" />
            <InputText id="boxFive" class="mfaInputValue" :class="{ mfaCodeErrorInput: mfaCodeError}" type="text" v-model="mfaCodeFive" @keyup=nextCodeValue(6) :maxlength="1" />
            <InputText id="boxSix" class="mfaInputValue" :class="{ mfaCodeErrorInput: mfaCodeError}" type="text" v-model="mfaCodeSix" @keyup=nextCodeValue(1) :maxlength="1" />
          </div>
        </div>
      </div>
      <div class="flex justify-content-between clarity-margin-bottom-s">
        <Button class="clarity-btn clarity-gradient-grey clarity-margin-left-s" @click="closeMFASetup">Dismiss</Button>
        <submit-button id="mfaSubmit" class="clarity-margin-right-s" @click="verifyMFACode" button-text="Validate and Proceed" ></submit-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {computed, ref, onMounted, inject} from 'vue';
import {useStore} from "vuex";
import api from '@/core/services/api';
import { toDrupalRequest } from '@/core/helpers';
import SubmitButton from "../common/layout/interactive/SubmitButton";
import { Amplify, Auth } from 'aws-amplify';
import config from '../../aws-exports';
import QRCodeVue3 from "qrcode-vue3";
import Dialog from 'primevue/dialog';

Amplify.configure({ ...config });

const domain = process.env.VUE_APP_COOKIE_DOMAIN;

if(domain && window.location.href.indexOf(domain) > -1) {
    //console.log('configure');
    Amplify.configure({
        Auth: {
            cookieStorage: {
                domain: domain,
                secure: false,
            },
        }
    });
}

export default {
  name: "Security",
  components: {SubmitButton, QRCodeVue3, Dialog},
  setup() {
    const store = useStore();
    const bus = inject('bus');
    const mfa = ref(store.getters.getMFAState ?? false);
    const showQRcode = ref(false);
    const mfaResponseCode = ref('');
    const qrcode = ref('');
    const disableMFAConfirmation = ref(false);
    const enableMFA = ref(false);
    const mfaCodeError = ref(false);
    const mfaBtnText = ref('');
    const mfaStatusMessage = ref('');
    const mfaStatusIcon = ref('');
    const mfaCodeOne = ref('');
    const mfaCodeTwo = ref('');
    const mfaCodeThree = ref('');
    const mfaCodeFour = ref('');
    const mfaCodeFive = ref('');
    const mfaCodeSix = ref('');

    onMounted(async () =>{
      const getMFA = await store.dispatch('checkUserMFA');
      if(getMFA === 'SOFTWARE_TOKEN_MFA') {
        mfa.value = true;
        mfaStatusMessage.value = 'Enabled';
        mfaStatusIcon.value = 'lock';
        mfaBtnText.value = 'Disable Multi-Factor Authentication';
      } else {
        mfa.value = false;
        mfaStatusMessage.value = 'Disabled';
        mfaStatusIcon.value = 'lock_open';
        mfaBtnText.value = 'Enable Multi-Factor Authentication';
      }
    });

    const showMFAPopover = () => {
      if(mfa.value) {
        // Show confirm delete popover
        disableMFAConfirmation.value = true;
      } else {
        // Show setup MFA popover
        enableUserMFA();
      }
    }

    const enableUserMFA = () => {
      const user = store.getters.user;
      Auth.setupTOTP(user).then((code) => {
        const str = "otpauth://totp/clarityGlobal:"+ user.attributes.email + "?secret=" + code + "&issuer=" + 'clarity';
        qrcode.value = str;
        enableMFA.value = true;
      })
      .catch(err => {
        console.log(err);
      });
    }

    const disableUserMFA = (async () => {
      const data = {type:'NOMFA'};
      store.dispatch('updateUserMFA',data);
      mfa.value = false;
      disableMFAConfirmation.value = false;
      mfaBtnText.value = 'Enable Multi-Factor Authentication';
      mfaStatusMessage.value = 'Disabled';
      mfaStatusIcon.value = 'lock_open';
      bus.emit('mfaOff');
      // update NG_USER_DATA
      store.dispatch('updateSettings', {
          type: 'settings',
          loading: 'settings-mfa',
          title: 'MFA Options',
          payload: {
            mfaEnabled: false
          }
        });
      // fire off email
      const endpoint = '/mfaemail';
      const response = await api.post(
        `${toDrupalRequest(endpoint)}`,
        JSON.stringify({'email_type':'disable'}),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if(!response) {
        console.log(response);
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    })

    const closeMFAConfirm = () => {
      if(disableMFAConfirmation.value === true) {
        disableMFAConfirmation.value = false;
      }
    }

    const closeMFASetup = () => {
      if(enableMFA.value === true) {
        enableMFA.value = false;
        mfaCodeError.value = false;
      }
    }

    const showVideoGuide = () => {
      //console.log('show video guide');
      bus.emit('showMFAVideo');
      if(enableMFA.value === true) {
        enableMFA.value = false;
        mfaCodeError.value = false;
      }

    }

    const closeCodeError = () => {
      mfaCodeError.value = false;
    }

    const nextCodeValue = (box) => {
      switch (box) {
        case 1:
          document.getElementById('mfaSubmit').focus();
          break;
        case 2:
          document.getElementById('boxTwo').focus();
          break;
        case 3:
          document.getElementById('boxThree').focus();
          break;
        case 4:
          document.getElementById('boxFour').focus();
          break;
        case 5:
          document.getElementById('boxFive').focus();
          break;
        case 6:
          document.getElementById('boxSix').focus();
          break;
        default:
          break;
      }
    }

    const verifyMFACode = (async () => {
      const code = mfaCodeOne.value + mfaCodeTwo.value + mfaCodeThree.value + mfaCodeFour.value + mfaCodeFive.value + mfaCodeSix.value;
      const data = {code:code};
      const result = await store.dispatch('setupUserMFA',data);
      if(result) {
        mfa.value = true;
        enableMFA.value = false;
        mfaBtnText.value = 'Disable Multi-Factor Authentication';
        mfaStatusMessage.value = 'Enabled';
        mfaStatusIcon.value = 'lock';
        bus.emit('mfaOn');
        // update NG_USER_DATA table
        store.dispatch('updateSettings', {
          type: 'settings',
          loading: 'settings-mfa',
          title: 'MFA Options',
          payload: {
            mfaEnabled: true
          }
        });
        // fire off email
        const endpoint = '/mfaemail';
        const response = await api.post(
          `${toDrupalRequest(endpoint)}`,
          JSON.stringify({'email_type':'setup'}),
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if(!response) {
          console.log(response);
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } else {
        mfaCodeError.value = true;
        mfaCodeOne.value = '';
        mfaCodeTwo.value = '';
        mfaCodeThree.value = '';
        mfaCodeFour.value = '';
        mfaCodeFive.value = '';
        mfaCodeSix.value = '';
      }
    });

    const loadingState = computed(() => store.getters.isLoading('settings-security'))

    return {
      verifyMFACode,
      showMFAPopover,
      enableUserMFA,
      disableUserMFA,
      closeMFAConfirm,
      closeMFASetup,
      nextCodeValue,
      closeCodeError,
      showVideoGuide,
      mfa,
      loadingState,
      showQRcode,
      mfaResponseCode,
      qrcode,
      disableMFAConfirmation,
      enableMFA,
      mfaBtnText,
      mfaStatusMessage,
      mfaStatusIcon,
      mfaCodeOne,
      mfaCodeTwo,
      mfaCodeThree,
      mfaCodeFour,
      mfaCodeFive,
      mfaCodeSix,
      mfaCodeError
    }
  }
}
</script>

<style>
  .codeErrorCard {
    margin: 30px 0px 30px 0px;
    padding: 30px 20px 30px 30px;
    background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #D1313880;
    border-radius: 27px;
  }
  .mfaErrorTitle h3 {
    padding: 0px;
    margin: 0px !important;
    color: var(--clarity-red);
  }
  .mfaErrorTitle .material-icons {
    vertical-align: top;
    margin-right: 10px;
  }
  .mfaErrorMsg {
    margin-left: 37px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 18px;
    color: var(--clarity-dark-grey);
  }
  .mfaCodeErrorInput {
        border: solid 1px #D1313880;
    }

  .dropdown-label {
    font-weight: bold;
    margin: 20px 0px;
  }
  .button {
    padding: 15px 45px;
  }
  .mfaStatusBar {
    margin: 0px 30px 0px 30px;
    height: 54px;
    border-radius: 30px;
    background-color: var(--clarity-snow-grey);
    border: 1px solid var(--clarity-light-grey);
    color: var(--clarity-blue);
    padding: 15px;
  }
  .statusMsg {
    font-weight: bold;
    color: var(--clarity-blue);
  }
  .statusMsgDisabled {
    font-weight: 100;
    color: var(--clarity-dark-grey);
  }
  .material-icons-outlined {
    vertical-align: bottom;
    margin-right: 14px;
  }
  .material-icons {
    vertical-align: top;
    margin-right: 10px;
}
  .mfaCodeEntry {
    margin-top: 45px;
    margin-bottom: 20px;
  }
  .mfaInputValue {
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0px 20px 0px 0px;
    font-size: 18px;
    color: var(--clarity-grey);
  }
  .showEnableMFABox {
    width: 1060px;
    max-width: 90%
  }
  .showEnableMFABox h3 {
    margin: 36px 0px 0px 30px;
  }
  .showDisableMFABox {
    width:600px;
  }
  .showDisableMFABox h3 {
    margin: 36px 0px 0px 30px;
  }
  .showDisableMFABox p {
    margin: 20px 30px 30px 30px;
  }
  .actionBtns button {
    padding: 15px 45px 15px 45px !important;
    margin: 0px 30px 35px 30px;
  }
  .p-dialog {
    /* z-index: 2500 !important; */
  }
  .clarity-close-modal-btn {
    margin: 20px 20px 0px 0px !important;
    width: 40px !important;
    height: 40px !important;
  }
  .code-text-bold {
    font-weight: bold;
  }
  .mfaVideoBtn {
    margin-right: 30px;
  }
  .greyItalic {
    color: var(--clarity-grey);
    font-style: italic;
  }
  .renderQrCode {
    height: 538px;
    text-align: center;
    padding: 80px 0px 60px 0px;
    margin: 30px 0px 30px 0px;
    background-image: url('../../assets/img/smartphone-mfa.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .renderQrCode h4 {
    text-align: center;
  }
  .renderQrCode a {
    color: var(--clarity-grey);
    text-decoration: underline;
  }
  .osIcons p, .osIcons img {
    vertical-align: middle;
  }
  .osIcons img {
    margin-right: 10px;
  }
  .osIcons a {
    color: var(--clarity-dark-grey);
  }
</style>
