<template>
    <div class="flex-column">
        <div class="flex align-items-end justify-content-between">
            <div v-if="currentSendMethod" class="flex-column" style="width: 75%">
                <p class="body-text"><b>How to receive your IMS Alerts</b></p>
                <Checkbox v-model="byEmail" :binary="true" /><label class="method-label" :class="{blue: byEmail}"><span :class="{boldtext: byEmail}">By Email</span> (on {{ clientEmail }}) </label>
                <Checkbox v-model="byPost" :binary="true" class="clarity-margin-left-m" /><label class="method-label" :class="{selectedMethod: byPost}">By Post </label>
                <Checkbox v-model="bySecureMessage" :binary="true" class="clarity-margin-left-m" /><label class="method-label" :class="{selectedMethod: bySecureMessage}">Secure Message </label>
            </div>
            <div style="width: 206px;">
                <submit-button @click="saveIMSmethod" :loading="loadingState" button-text="Save Changes" ></submit-button>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitButton from "../common/layout/interactive/SubmitButton";
import {ref, computed} from "vue";
import {useStore} from "vuex";

export default {
    name: "Ims",
    components: {SubmitButton},
    setup() {
        const store = useStore();
        const clientEmail = computed(() => store.getters.getEmail)
        const current = computed(() => store.getters.getImsSendMethod)
        const byEmail = ref(false);
        const byPost = ref(false);
        const bySecureMessage = ref(false);
        const noSelection = ref(true);

        const loadingState = computed(() => store.getters.isLoading('save-ims-send'))

        const currentSendMethod = computed(() => {
            if(current.value) {
                current.value.split(",").forEach(element => {
                    switch (element) {
                        case 'email':
                            byEmail.value = true;
                            break;
                        case 'securemessaging':
                            bySecureMessage.value = true;
                            break;
                        case 'post':
                            byPost.value = true;
                            break;
                        case 'pleaseselect':
                            noSelection.value = true;
                            break;
                        case 'both':
                            byPost.value = true;
                            byEmail.value = true;
                            break;
                        default:
                            noSelection.value = true;
                            break;
                    }
                });
            }
            return true;
        });

        const saveIMSmethod = async () => {
            await store.dispatch('setLoading', {id: 'save-ims-send', state: true})
            const methods = [];
            if(byEmail.value) {
                methods.push('email');
            }
            if(byPost.value) {
                methods.push('post');
            }
            if(bySecureMessage.value) {
                methods.push('securemessaging');
            }
            let payload = '';
            if(methods.length === 0) {
                payload += 'pleaseselect'
            } else {
                for(let x = 0; x < methods.length; x++) {
                    payload += methods[x];
                    if(x !== methods.length-1) {
                        payload += ',';
                    }
                }
            }

            await store.dispatch('setImsSendMethodOption',{
                type: 'settings',
                loading: 'settings-ims',
                title: 'IMS Send Method',
                payload: {
                    "method": payload
                }
            });

            await store.dispatch('setLoading', {id: 'save-ims-send', state: false})
        }

        return {
            clientEmail,
            byEmail,
            byPost,
            bySecureMessage,
            noSelection,
            currentSendMethod,
            saveIMSmethod,
            loadingState
        }
    }
}
</script>

<style scoped lang="scss">
.selectedMethod {
    color: #0058A9;
    font-weight: bold;
}
.boldtext {
    font-weight: bold;
}
.method-label {
    margin-left: 13px;
}
</style>
