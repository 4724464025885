<template>
  <div class="flex-column">
    <div class="flex">
      Valuation pages will display in this currency when you first load the page. Valuation downloads will also be in
      the selected currency.
    </div>
    <div class="flex align-items-end justify-content-between" style="padding-top: 30px;">
      <div class="flex-column" style="width: 44%">
        <div class="dropdown-label">Default Reporting Currency</div>
        <Dropdown class="full-width" :model-value="reportingCurrency" :options="currencyOptions" optionLabel="currencyCode" optionValue="currencyCode"  @change="updateCurrencySelected"></Dropdown>
      </div>
      <div style="width: 206px;">
        <submit-button @click="saveReportingCurrency" :loading="loadingState" button-text="Save Changes" ></submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref, watch} from "vue";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "Currency",
  components: {SubmitButton},
  setup() {
    const store = useStore()
    const initialDefault = computed(() => store.getters.getReportingCurrency)
    const reportingCurrency = ref(initialDefault.value ?? '')

    watch(initialDefault, (value) => {
        reportingCurrency.value = value;
    })

    onMounted(() => {
      getCurrencyOptions()
    })

    const getCurrencyOptions = () => {
      store.dispatch('getCurrencyOptions')
    }

    const updateCurrencySelected = (data) => {
      reportingCurrency.value = data.value
    }

    const currencyOptions = computed(() => {
      const options = store.state.selections.currencyOptions;
      if(options && options.error){
        store.dispatch('addNotification', {severity: 'error', summary: 'Currency Select', detail: options.message })
        return [
          {"currencyCode":"GBP","currencySymbol":"\u00a3","numericCode":"826","conversionrate":"1.00000000000000","date":"2021-07-21"}
        ];
      }else{
        return options;
      }
    })

    const saveReportingCurrency = () => {
      store.dispatch('setLoading', {id: 'settings-currency', state: true})
      store.dispatch('updateSettings', {
        type: 'settings',
        loading: 'settings-currency',
        title: 'Currency',
        payload: {
          "defaultCurrency": reportingCurrency.value
        }
      })
      store.dispatch('setCurrencySelected', reportingCurrency.value)
    }

    const loadingState = computed(() => store.getters.isLoading('settings-currency'))

    return {
      currencyOptions,
      updateCurrencySelected,
      reportingCurrency,
      saveReportingCurrency,
      loadingState
    }
  }
}
</script>

<style scoped>
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}
.half {
  width: 50%;
}
</style>
