<template>
  <div>
    <div class="flex-column">
      <div class="flex half">
        <div class="flex full-width">
          <div class="field col cl-input">
            <div class="dropdown-label">Current Password:</div>
            <InputText type="password" placeholder="Current Password..." class="p-inputtext-lg w-full"
                       v-model="currentPassword"/>
          </div>
        </div>
      </div>
      <div class="flex justify-content-between">
        <div class="flex half">
          <div class="field col cl-input">
            <div class="dropdown-label">New Password:</div>
            <Password type="password" placeholder="New Password..." class="p-inputtext-lg w-full"
                      v-model="newPassword"/>
          </div>
        </div>
        <div class="flex half">
          <div class="field col cl-input">
            <div class="dropdown-label">Confirm New Password:</div>
            <InputText type="password" placeholder="Confirm New Password..." class="p-inputtext-lg w-full"
                       v-model="passwordCheck" @keyup.enter="passwordReset"/>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="flex justify-content-between">
      <div>
        <p style="font-weight: bold">To make your password stronger:</p>
        <ul>
          <li>Make it at least 6 characters.</li>
          <li>Add lowercase letters.</li>
          <li>Add uppercase letters.</li>
          <li>Add numbers.</li>
          <li>Add punctuation.</li>
        </ul>
      </div>
      <div style="width: 206px;">
        <submit-button @click="passwordReset" :loading="loadingState" button-text="Save Changes" ></submit-button>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from '@aws-amplify/auth';
import {computed, ref} from "vue";
import {useStore} from "vuex";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "PasswordReset",
  components: {SubmitButton},
  emits:['pwdChanged'],
  setup(props, context) {
    const store = useStore()
    const currentPassword = ref('')
    const newPassword = ref('')
    const passwordCheck = ref('')
    const passwordErrorMessages = ref('')

    const passwordReset = async () => {
      store.dispatch('setLoading', {id: 'settings-password', state: true})
      if(newPassword.value === passwordCheck.value){
        Auth.currentAuthenticatedUser()
            .then(user => {
              return Auth.changePassword(user, currentPassword.value, newPassword.value);
            })
            .then(data => {
              console.log('data is ', data);
              context.emit('pwdChanged')
              currentPassword.value = ''
              newPassword.value = ''
              passwordCheck.value = ''
              store.dispatch('setLoading', {id: 'settings-password', state: false})
            })
            .catch(err => {
              console.log('error is ', err);
              const errMsg = String(err);
              const errCurrentPwd = errMsg.includes('previousPassword');
              const errNewPwd = errMsg.includes('proposedPassword');
              const errNotAuth = errMsg.includes('NotAuthorizedException');
              const errNewPwdSgth = errMsg.includes('InvalidPasswordException');
              if(errCurrentPwd || errNotAuth) {
                passwordErrorMessages.value = 'Current password error';
              }
              else if(errNewPwd) {
                passwordErrorMessages.value = 'New password error';
              }
              else if(errNewPwdSgth) {
                passwordErrorMessages.value = err;
              } else {
                passwordErrorMessages.value = err;
              }
              store.dispatch('addNotification',{severity: 'error', summary: 'Error changing password', detail: passwordErrorMessages.value, life: 4000});
              store.dispatch('setLoading', {id: 'settings-password', state: false})
            });
      } else {
        await store.dispatch('addNotification',{severity: 'error', summary: 'Password mis-match', detail: 'New password entries do not match', life: 4000});
        store.dispatch('setLoading', {id: 'settings-password', state: false})
      }
    }

    const loadingState = computed(() => store.getters.isLoading('settings-password'))

    return {
      currentPassword,
      newPassword,
      passwordCheck,
      passwordReset,
      loadingState
    }
  }
}
</script>

<style>
.half {
  width: 50%;
}
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}
</style>
